import './Header.css'
import React from 'react'
import { Link } from 'react-router-dom'


function ServicosHeader() {

function goToDiferenciais() {
    setTimeout(() => {
        const goToView = document.getElementById('diferenciais')
        goToView.scrollIntoView()
    }, 800);
}

function goToSobre() {
    setTimeout(() => {
        const goToView = document.getElementById('sobre')
        goToView.scrollIntoView()
    }, 800);
}

    return (
      <div className='servicosheader'>
        <div className='servicosheader-area'>
          <Link to='/'><img src='./logo.png' alt='Logo da LetterConsulting' /></Link>
          <div className='servicosheader-menuContato'>
            <div className='servicosheader-menu'>
            <ul>
                            <li><Link to='/' onClick={goToDiferenciais}>Diferenciais</Link></li>
                            <li><Link to='/' onClick={goToSobre}>Sobre a letter</Link></li>
                            <li><a href='/' className='active'>Serviços</a></li>
                        </ul>
            </div>
            <div className='servicosheader-contato'>
              <a href="https://api.whatsapp.com/send?phone=5511988048672"><i className="fa-brands fa-whatsapp"></i><span>11</span>988048672</a>
            </div>
            <div className='servicosheader-analise'>
              <Link to='/'>Solicitar análise</Link>
            </div>
          </div>
        </div>
      </div>
    )
}

export default ServicosHeader





