import './style.css'

export default function ComplianceDeContratos() {
    return (
        <div id='complianceDeContratos' className='complianceDeContratos'>
            <div className='complianceDeContratos-area'>
                <h1>Compliance de Contratos</h1>
                <div className='complianceDeContratos-box'>
                    <div className='complianceDeContratos-texto'>
                        <p>O compliance é um tema que vem ganhando cada vez mais atenção nas empresas. Para advogados, gestores e demais profissionais que lidam diariamente com a gestão de contratos e outros documentos jurídicos, manter o compliance é fundamental para garantir transparência nas relações com clientes, fornecedores e demais stakeholders.</p>
                        <p style={{fontWeight: "bold"}}>Aliar o compliance à automatização do gerenciamento de contratos e contratações traz uma gestão mais ágil e centralizada.</p>
                        <p style={{fontWeight: "bold"}}>Com uma gestão de contratos transparente, a empresa pode:</p>
                        <ul>
                            <li>Garantir o histórico de todas as ações, negociações e acordos com fornecedores;</li>
                            <li>Oferecer documentação detalhada e comunicação clara de todas as fases de um contrato da elaboração, passando pela negociação e aprovação, até o encerramento;</li>
                        </ul>
                    </div>
                    <div className='complianceDeContratos-texto'>
                        <ul>
                            <li>Ter maior controle dos prazos de vencimento e renovação de contratos;</li>
                            <li>Estabelecer políticas de segurança da informação e segurança cibernética;</li>
                            <li>Contar com uma política clara de contratação de colaboradores e prestadores de serviço;</li>
                            <li>Monitorar os valores, prazos e quantidades de suprimentos negociados com fornecedores;</li>
                            <li>Garantir o cumprimento dos níveis de SLA (Service Level Agreement ou Acordo de Nível de Serviço) estabelecidos nos acordos;</li>
                            <li>Estabelecer critérios de seleção de fornecedores, garantindo idoneidade e maior qualidade;</li>
                            <li>Manter a adequação de todos os envolvidos quanto às políticas e diretrizes da empresa.</li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    )
}