import React, { useState } from 'react';
import './FaleConosco.css';
import { useNavigate } from "react-router-dom";

function FaleConosco() {
    const navigate = useNavigate();
    const [formSubmit, setFormSubmit] = useState(null);
    const [lgpdChecked, setLgpdChecked] = useState('')


    class FormSubmit {
        constructor(settings) {
            this.settings = settings;
            this.form = document.querySelector(settings.form);
            this.formButton = document.querySelector(settings.button);
            if (this.form) {
                this.url = this.form.getAttribute("action");
            }
            this.sendForm = this.sendForm.bind(this);
        }

        displaySuccess() {
            navigate("/obrigado");
        }

        displayError() {
            this.form.innerHTML = this.settings.error;
        }

        getFormObject() {
            const formObject = {};
            const fields = this.form.querySelectorAll("[name]");
            fields.forEach((field) => {
                formObject[field.getAttribute("name")] = field.value;
            });
            return formObject;
        }

        onSubmission(event) {
            event.preventDefault();
            event.target.disabled = true;
            event.target.innerText = "Enviando...";
        }

        async sendForm(event) {
            if(document.querySelector('#LGPDValidation').checked) {
                try {
                    this.onSubmission(event);
                    await fetch(this.url, {
                        method: "POST",
                        headers: {
                            "Content-Type": "application/json",
                            Accept: "application/json",
                        },
                        body: JSON.stringify(this.getFormObject()),
                    });
                        this.displaySuccess();
                } catch (error) {
                    this.displayError();
                    throw new Error(error);
                }
            } else {
                event.preventDefault();
                setLgpdChecked("Precisa aceitar os termos")
            }
            
        }

        init() {
            if (this.form) this.formButton.addEventListener("click", this.sendForm);
            return this;
        }
    }

    React.useEffect(() => {
        const formSubmit = new FormSubmit({
            form: "[data-form]",
            button: "[data-button]",
            success: "<h1 class='success' >Mensagem enviada!</h1>",
            error: "<h1 class='error'>Não foi possível enviar sua mensagem.</h1>",
        });
        formSubmit.init();
        setFormSubmit(formSubmit);
    }, [navigate]);

    return (
        <div className='faleConosco'>
            <div className='faleConosco-box'>
                <div className='faleConosco-box-texto'>
                    <form action="https://formsubmit.co/ajax/marcelo.mariano@letterconsulting.com.br" method="POST" data-form>
                        <p>Faça uma consulta GRATUITA sobre possíveis créditos tributários devidos pelo Governo à sua empresa.<strong style={{color: "red"}}>{lgpdChecked}</strong></p>
                        <div>
                            <label htmlFor="nome">Nome</label>
                            <input type="text" id="nome" name="nome" placeholder="Digite seu nome" required />
                        </div>
                        <div>
                            <label htmlFor="email">E-mail</label>
                            <input type="email" id="email" name="email" placeholder="Digite seu e-mail" required />
                        </div>
                        <div>
                            <label htmlFor="celular">Celular</label>
                            <input type="number" id="celular" name="celular" placeholder="Digite Seu Número" required />
                        </div>
                        <div>
                            <div className="checkbox-list" required >
                                <div className="checkbox">
                                    <label>
                                        <input type="checkbox" id="LGPDValidation" name="LGPDValidation" value="1" /> Autorizo o recebimento de contato via celular ou e-mail para, exclusivamente, obter informações sobre os serviços prestados pela Letter Consulting.
                                    </label>
                                </div>
                            </div>
                        </div>
                        <div className="botao-verde">
                            <button type="submit" data-button>Enviar</button>
                        </div>
                    </form>
                </div>

            </div>
        </div>

    )
}

export default FaleConosco
