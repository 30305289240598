import './Dados.css'

export default function Dados() {
    return (
        <div className='dados'>
            <div className='dados-area'>
                <div className='dados-box'>
                    <h1>+R$ 9,5 bi</h1>
                    <p>de créditos recuperados</p>
                </div>
                <div className='dados-box'>
                    <h1>+5 mil</h1>
                    <p>clientes atendidos</p>
                </div>
                <div className='dados-box'>
                    <h1>+300</h1>
                    <p>colaboradores capacitados</p>
                </div>
            </div>
        </div>
    )
}