import { Link } from 'react-router-dom'
import './ServicosCards.css' 

function ServicosCards() {

    function goToRTP() {
        setTimeout(() => {
            const goToView = document.getElementById('revisaoTributaria')
            goToView.scrollIntoView()
        }, 200);
    }

    function goToIDI() {
        setTimeout(() => {
            const goToView = document.getElementById('impostosDiretosIndiretos')
            goToView.scrollIntoView()
        }, 200);
    }

    function goToTNJP() {
        setTimeout(() => {
            const goToView = document.getElementById('transacoes')
            goToView.scrollIntoView()
        }, 200);
    }

    function goToBPOF() {
        setTimeout(() => {
            const goToView = document.getElementById('bpos')
            goToView.scrollIntoView()
        }, 200);
    }

    function goToPTSR() {
        setTimeout(() => {
            const goToView = document.getElementById('planejamentoTributario')
            goToView.scrollIntoView()
        }, 200);
    }

    function goToCC() {
        setTimeout(() => {
            const goToView = document.getElementById('complianceDeContratos')
            goToView.scrollIntoView()
        }, 200);
    }

    function goToLGPD() {
        setTimeout(() => {
            const goToView = document.getElementById('lgpd')
            goToView.scrollIntoView()
        }, 200);
    }

    function goToIT() {
        setTimeout(() => {
            const goToView = document.getElementById('internationalTax')
            goToView.scrollIntoView()
        }, 200);
    }

    function goToC() {
        setTimeout(() => {
            const goToView = document.getElementById('contencioso')
            goToView.scrollIntoView()
        }, 200);
    }

    return (
        <div className='servicosCards'>
            <div className='servicosCards-cards'>
                <div className='servicosCards-cards-itens'>
                    <img src='./icone-revisao.png' alt=''/>
                    <h2>Revisão Tributária e Previdenciária</h2>
                </div>
                <Link to='/servicos' onClick={goToRTP}>Saiba mais<img src='./saibamais.png' alt=''/></Link>
            </div>
            <div className='servicosCards-cards'>
                <div className='servicosCards-cards-itens'>
                    <img src='./icone-impostos.png' alt=''/>
                    <h2>Impostos Diretos e Indiretos</h2>
                </div>
                <Link to='/servicos' onClick={goToIDI}>Saiba mais<img src='./saibamais.png' alt=''/></Link>
            </div>
            <div className='servicosCards-cards'>
                <div className='servicosCards-cards-itens'>
                    <img src='./icone-transacao.png' alt=''/>
                    <h2>Transação e NJP</h2>
                </div>
                <Link to='/servicos' onClick={goToTNJP}>Saiba mais<img src='./saibamais.png' alt=''/></Link>
            </div>
            <div className='servicosCards-cards'>
                <div className='servicosCards-cards-itens'>
                    <img src='./icone-bpo.png' alt=''/>
                    <h2>BPO Fiscal</h2>
                </div>
                <Link  to='/servicos' onClick={goToBPOF}>Saiba mais<img src='./saibamais.png' alt=''/></Link>
            </div>
            <div className='servicosCards-cards'>
                <div className='servicosCards-cards-itens'>
                    <img src='./icone-planejamento.png' alt=''/>
                    <h2>Planej. Tributário, Sucessório e Reestruturação</h2>
                </div>
                <Link to='/servicos' onClick={goToPTSR}>Saiba mais<img src='./saibamais.png' alt=''/></Link>
            </div>
            <div className='servicosCards-cards'>
                <div className='servicosCards-cards-itens'>
                    <img src='./icone-compliance.png' alt=''/>
                    <h2>Compliance de Contratos</h2>
                </div>
                <Link to='/servicos' onClick={goToCC}>Saiba mais<img src='./saibamais.png' alt=''/></Link>
            </div>
            <div className='servicosCards-cards'>
                <div className='servicosCards-cards-itens'>
                    <img src='./icone-lgpd.png' alt=''/>
                    <h2>LGPD</h2>
                </div>
                <Link to='/servicos'onClick={goToLGPD}>Saiba mais<img src='./saibamais.png' alt=''/></Link>
            </div>
            <div className='servicosCards-cards'>
                <div className='servicosCards-cards-itens'>
                    <img src='./icone-tax.png' alt=''/>
                    <h2>International Tax</h2>
                </div>
                <Link to='/servicos'onClick={goToIT}>Saiba mais<img src='./saibamais.png' alt=''/></Link>
            </div>
            <div className='servicosCards-cards'>
                <div className='servicosCards-cards-itens'>
                    <img src='./icone-contencioso.png' alt=''/>
                    <h2>Contencioso: Adm/Tributário</h2>
                </div>
                <Link to='/servicos'onClick={goToC}>Saiba mais<img src='./saibamais.png' alt=''/></Link>
            </div>
            <div className='servicosCards-cards'>
                <div className='servicosCards-cards-itens'>
                    <img src='./icone-prdi.png' alt=''/>
                    <h2>PRDI</h2>
                </div>
                <Link to='/servicos'>Saiba mais<img src='./saibamais.png' alt=''/></Link>
            </div>
        </div>
    )
}

export default ServicosCards