import './MainThanksYou.css'
import { Link } from "react-router-dom";

function TYMainSection() {

    return (
        <div className='TYmainSection'>
            <h1>Obrigado! Sua mensagem foi <br/>recebida com sucesso.</h1>
            <p>Em breve um de nossos especialistas entrará em contato com você.</p>
            <div className='botao-verde'>
            <Link to="/">Voltar para o site</Link>
            </div>
        </div>
    )
}

export default TYMainSection