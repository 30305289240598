import { useEffect, useState } from "react"
import "./style.css"

export default function Contencioso() {

    const [br,setBr] = useState()

    useEffect(() => {
        if(window.innerWidth <= 550) {
            setBr(<br/>)
        }
    },[])


    return (
        <div id="contencioso" className="contencioso">
            <div className="contencioso-area">
                <h1 >Contencioso <br/>Administrativo/{br}Tributário</h1>
                <div className="contencioso-box">
                    <div className="contencioso-text">
                        <p>Todo contribuinte tem o direito a defesa administrativa, caso seja autuado pela fiscalização tributária, sem necessariamente ter que recorrer ao órgão judiciário, ou seja, junto às próprias repartições fiscalizadoras.</p>
                        <p>As exigências contidas nos autos de infração ou documentos equivalentes, podem ser impugnadas administrativamente pelos contribuintes ou responsáveis, na forma prescrita nas legislações tributárias que os regulam, anexando provas com o objetivo de reverter a cobrança dos tributos.</p>
                        <img style={{width: "100%"}} src="./contencioso.png" alt="" />
                    </div>
                    <div className="contencioso-text">
                        <p style={{fontWeight: "bold"}}>Nossos principais serviços são:</p>
                        <ul>
                            <li>Elaboração de impugnações, manifestações de inconformidade, recursos administrativos e demais defesas administrativas nas esferas federal, estadual, municipal e distrital</li>
                            <li>Formulação de consulta aos órgãos administrativos fazendários</li>
                            <li>Acompanhamento processual até a última instância administrativa</li>
                            <li>Sustentação oral no CARF, TIT e demais órgãos administrativos</li>
                            <li>Ajuizamento de Ações (Mandado de Segurança, Declaratória e Anulatória) contra incidência de tributos, bem como propositura de ação de repetição de indébito para a recuperação de valores pagos indevidamente</li>
                            <li>Sustentação oral em qualquer instância</li>
                            <li>Elaboração de defesas e recursos em qualquer instância judicial</li>
                            <li>Atuação incisiva em processos de execução fiscal (exceção de pré-executividade, embargos à execução e demais manifestações e defesas contra cobranças executivas)</li>
                            <li>Acompanhamento processual até a última instância judicial</li>
                            <li>Administração de passivos tributários em geral</li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    )
}