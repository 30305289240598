import './style.css'

export default function PlanejamentoTributario() {
    return (
        <div id='planejamentoTributario' className='planejamentoTributario'>
            <div className='planejamentoTributario-area'>
                <div className='planejamentoTributario-box'>
                    <h1>Planejamento Tributário, Sucessório e Reestruturação</h1>
                    <p>Uma enorme necessidade de obtenção e gestão de recursos financeiros faz com que as empresas avaliem com regularidade sua gestão tributária, visando a eficiência fiscal. O planejamento tributário é um conjunto de sistemas legais que visam trazer eficiência ao pagamento de tributos e existem centenas de tipos de planejamentos tributários que se aplicam a cada companhia.</p>
                    <p>Os objetivos são:</p>
                    <p style={{color: "#BD8C3D", fontWeight: "500"}}>Estratégico</p>
                    <ul>
                        <li>Planejamento tributário estratégico visa a projeção do futuro da gestão, considerando os seus valores, missão e visão, basicamente precisamos:</li>
                        <li>
                            <ul>
                                <li>Definição do regime tributário ideal</li>
                                <li>Incentivos fiscais que serão utilizados</li>
                                <li>Estrutura de profissionais que participarão das decisões financeiras</li>
                            </ul>
                        </li>
                        <li>O planejamento estratégico tem como principal objetivo a adequação da gestão à legislação brasileira, que é severa e complexa, a curto, médio e longo prazo.</li>
                    </ul>
                </div>
                <div className='planejamentoTributario-box'>
                    <img style={{width: "100%"}} src='./planejamento-tributario.png' alt='' />
                    <p style={{color: "#BD8C3D", fontWeight: "500"}}>Operacional</p>
                    <p>É focado no cumprimento das obrigações fiscais no dia a dia da empresa em um período de 3 a 6 meses, o planejamento tributário operacional organiza todas as datas e valores que devem ser respeitados, determina as metodologias e os responsáveis pelas entregas, sempre “linkado” a estratégia de transferir direitos e deveres para sucessores, e minimizar os custos envolvidos para garantir a proteção do patrimônio no longo prazo. Não se trata, portanto, de simplesmente decidir por um caminho, mas de estabelecer uma estratégia quando diante das possibilidades em aberto, há uma considerável margem de personalização no trabalho conforme as características de cada patrimônio e cada companhia.</p>
                </div>
            </div>
        </div>
    )
}