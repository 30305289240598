import './Header.css'
import React from 'react'


function Header() {
    return (
      <div className='header'>
        <div className='header-area'>
          <a href='/'><img src='./logo.png'alt='Logo da LetterConsulting' /></a>
          <div className='header-menuContato'>
            <div className='header-menu'>
                <ul>
                  <li><a href='#diferenciais'>Diferenciais</a></li>
                  <li><a href='#sobre'>Sobre a Letter</a></li>
                  <li><a href='#servicos'>Serviços</a></li>
                </ul>
            </div>
            <div className='header-contato'>
              <a href="https://api.whatsapp.com/send?phone=5511988048672"><i className="fa-brands fa-whatsapp"></i><span>11</span>98804.8672</a>
            </div>
          </div>
        </div>
      </div>
    )
}

export default Header