import './style.css'

export default function OqueFazemos() {
    return (
        <div className='oqueFazemos'>
            <div className='oqueFazemos-area'>
                <h1>O que fazemos</h1>
                <div className='oqueFazemos-texto'>
                <div className='oqueFazemos-paragrafo'>
                    <p>Aqui inspiramos confiança e empoderamos a mudança em tudo o que fazemos, nossa visão é ser a escolha certa na parceria que oferecemos. A base para isso é a qualidade nos trabalhos, o compromisso de contribuir para o sucesso dos nossos clientes e no desenvolvimento da sociedade em geral.</p>
                    <p>Cada vez mais temos a certeza que o trabalho que desempenhamos no dia a dia é coerente com nossos valores e aspirações, a importância da participação da Letter Consulting e de cada um de nós na promoção das mudanças de que a sociedade necessita, é um pilar inegociável para nós.</p>
                </div>
                <div className='oqueFazemos-paragrafo'>
                    <p>Essa mudança reflete a nossa crença na ideia de que todos temos um papel a desempenhar na construção de um mundo mais justo e sustentável, e que em linhas gerais, faz com que aceitemos as constantes mudanças sabendo que contribuem para melhorar os hábitos que ajudam a reduzir o nosso impacto social, promovem valores de boa governança, diversidade, inclusão e nos tornam participantes diretos em ações sociais que beneficiam nossas famílias, empresas e comunidades em que atuamos.</p>
                    <p>Outro de nossos pilares é a “integridade acima de tudo”, fazer a diferença de forma transparente sempre trazendo a verdade, o cuidado com as empresas e as pessoas, nos torna uma equipe diferenciada que busca os desafios constantemente.</p>
                </div>
                </div>
            </div>
        </div>
    )
}