import './style.css'

export default function InternationalTax() {
    return (
        <div id='internationalTax' className='internationalTax'>
            <div className='internationalTax-area'>
                <h1>International Tax</h1>
                <div className='internationalTax-box'>
                    <div className='InternationalTax-text'>
                        <p>Conjunto de soluções tributárias específicas voltadas a empresas brasileiras em processos de internacionalização, organizações estrangeiras que realizam investimentos no Brasil e organismos investidores em geral, todos esses serviços ganham alto potencial em um contexto de globalização da economia.</p>
                        <p style={{fontWeight: "bold"}}>Nossos principais serviços são:</p>
                        <ul>
                            <li>Apoio a questões fiscais envolvendo transações internacionais;</li>
                            <li>Planejamento da distribuição de dividendos ou redução de capital para investidores não residentes no Brasil;</li>
                            <li>Suporte a operações de fusões, cisões e incorporações, visando a compra e venda de empresas, envolvendo investidores estrangeiros;</li>
                            <li>Auxílio em relação ao registro no Banco Central de capitais estrangeiros via investimento direto em moeda ou bens, royalties e empréstimos internacionais;</li>
                            <li>Análise sobre BEPS (Base Erosion and Profit Shifting) relativa ao planejamento e à tributação internacional;</li>
                            <li>Consultoria tributária aplicável ao capital estrangeiro, considerando tratados internacionais assinados pelo Brasil para evitar dupla tributação à sua empresa;</li>
                            <li>Análise de impactos tributários pertinentes a investimentos brasileiros no exterior, considerando normas de controle do fluxo de capitais pelo Banco Central;</li>
                            <li>Otimização, para fins fiscais, de investimentos estrangeiros no País</li>
                        </ul>
                    </div>
                    <div className='InternationalTax-text'>
                        <ul>
                            <li>Investimentos diretos efetuados no Brasil por não-residentes;</li>
                            <li>Investimentos diretos efetuados no exterior por residentes brasileiros;</li>
                            <li>Aquisições e/ou alienações, locais e internacionais, de investimentos ou negócios;</li>
                            <li>Reestruturações societárias;</li>
                            <li>Financiamentos à empresa local (aporte de capital social, empréstimos locais ou internacionais, conversão de dívidas em capital, etc.);</li>
                            <li>Normas constantes de tratados internacionais;</li>
                            <li>Registro do capital estrangeiro competente no Banco Central.</li>
                        </ul>
                        <img style={{width: "100%"}} src='./international-tax.png' alt='' />
                    </div>
                </div>
            </div>
        </div>
    )
}