import './style.css'

export default function LGPD() {
    return (
        <div id='lgpd' className='lgpd'>
            <div className='lgpd-area'>
                <div className='lgpd-box'>
                    <div className='lgpd-text'>
                        <h1>LGPD</h1>
                        <p>O novo impacto dos regulamentos em diferentes áreas da Organização será revisto e ajustado. As ações que serão realizadas são:</p>
                        <ul>
                            <li>Conhecimento dos processos existentes na organização e sua conformidade com os regulamentos atuais;</li>
                            <li>Análise Comparativa (GAP) entre a situação atual da organização, considerando as áreas de adaptação a LGPD, bem como as implementações que devem ser realizadas para atingir cada um dos objetivos de adaptação;</li>
                            <li>Plano de ação com a estratégia de adaptação a LGPD;</li>
                            <li>Definição do Comitê e do modelo de governança que permite a organização cumprir as obrigações regulatórias.</li>
                        </ul>
                    </div>
                    <div className='lgpd-text'>
                    <img style={{width: "100%"}} src='./lgpd.png' alt='' />
                    </div>
                </div>
                <img className='lgpdRules' src='./lgpd-rules.png' alt='' />
            </div>
        </div>
    )
}