import './style.css'

export default function BPOFiscal() {

    return (
        <div  id='bpos' className='bpoFiscal'>
            <div className='bpoFiscal-area'>
                    <h1>BPO Fiscal</h1>
                <div className='bpoFiscal-box'>
                    <div className='bpoFiscal-bpo'>
                        <p>A terceirização da atividade de controle fiscal, que podemos chamar de BPO (Business Process Outsourcing) Fiscal, apresenta todas as tarefas impostas pela legislação para atender as obrigações fiscais principais e acessórias nas esferas federal, estadual e municipal, e atualização das Matriz de Impostos.</p>
                        <p style={{fontWeight: "bold"}}>Atua, também, no processamento das informações e respectivos cruzamentos entre as informações em tempo real da sua empresa.</p>
                        <p>Uma vez definidas as informações relevantes para os negócios, são disponibilizados, diariamente, análises, relatórios e gráficos para orientar a estratégia e a tomada de decisão, bem como apoio no planejamento tributário da empresa.</p>
                        <ul>
                            <li style={{listStyle: "none"}}>Abaixo as principais atividades:</li>
                            <li style={{listStyle: "none"}}>
                                <ul>
                                    <li>Recebimento fiscal;</li>
                                    <li>Processamento de livros fiscais;</li>
                                    <li>Cálculos de impostos e gerenciamento de créditos fiscais;</li>
                                    <li>Preparação, cruzamento e entrega de obrigações fiscais eletrônicas e outros requerimentos de controle tributário;</li>
                                    <li>Relatórios e análises gerenciais adequados à necessidade de cada cliente;</li>
                                    <li>Opção de processamento no software fiscal da HUB integrado com o ERP do cliente;</li>
                                </ul>
                            </li>
                        </ul>
                    </div>
                    <div className='bpoFiscal-fiscal'>
                        <ul>
                            <li>Reprocessamento contábil, fiscal, trabalhista e previdenciário</li>
                            <li>Geração e retificação de obrigações fiscais, tais como ECD, ECF, EFD ICMS, EFD Contribuições etc.</li>
                        </ul>
                        <img style={{width: "100%"}} src='./transacoes.png' alt='' />
                    </div>
                </div>
            </div>
        </div>
    )
}

