import './Servicos.css'
import ServicosCards from './ServicosCards/ServicosCards'

function Servicos() {
    return (
        <div id='servicos' className='servicos'>
            <div className='servicos-texto'>
                <h1 >Serviços</h1>
                <p>Na Letter Consulting, inspiramos confiança e empoderamos a mudança em tudo o que fazemos <br/>por nossos clientes, por nossas pessoas e pelas comunidades a que servimos. Nossa visão <br/>compartilhada é nos tornarmos a escolha certa nos serviços que oferecemos, que são eles:</p>
            </div>
            <ServicosCards/>
        </div>
    )
}

export default Servicos