import { useEffect } from 'react'
import '../App.css'
import BPOFiscal from '../Components/SaibaMais/BPOFiscal'
import ComplianceDeContratos from '../Components/SaibaMais/ComplianceDeContratos'
import Contencioso from '../Components/SaibaMais/Contencioso'
import SaibaMaisFooter from '../Components/SaibaMais/Footer/Footer'
import ServicosHeader from '../Components/SaibaMais/Header/Header'
import ImpostosDiretosIndiretos from '../Components/SaibaMais/ImpostosDiretosIndiretos'
import InternationalTax from '../Components/SaibaMais/InternationalTax'
import LGPD from '../Components/SaibaMais/LGPD'
import OqueFazemos from '../Components/SaibaMais/OqueFazemos'
import PlanejamentoTributario from '../Components/SaibaMais/PlanejamentoTributario'
import RevisaoTributaria from '../Components/SaibaMais/RevisaoTributaria'
import Transacoes from '../Components/SaibaMais/Transacoes'


export default function SaibaMais() {

    useEffect(()=> {
        console.log(window.scrollY)
    },[])

    return (
        <div className='saibaMais'>
            <ServicosHeader />
            <OqueFazemos />
            <RevisaoTributaria />
            <ImpostosDiretosIndiretos />
            <Transacoes />
            <BPOFiscal />
            <PlanejamentoTributario />
            <ComplianceDeContratos />
            <LGPD />
            <InternationalTax />
            <Contencioso />
            <SaibaMaisFooter />
        </div>
    )
}