import './style.css'

export default function ImpostosDiretosIndiretos() {
    return (
        <div id='impostosDiretosIndiretos' className='impostosDiretosIndiretos'>
            <div className='impostosDiretosIndiretos-area'>
                <div className='impostosDiretosIndiretos-box'>
                    <h1>Impostos diretos e indiretos</h1>
                    <p>O Brasil tem uma das maiores e mais complexas taxas de contribuição do mundo, eles podem ser denominados indiretos ou diretos. Efetuamos a revisão dos cálculos dos tributos (IRPJ, CSLL, IPI, PIS, Cofins, IOF, ICMS e ISS), bem como, possíveis recuperações, suspensões, regimes especiais e benefícios fiscais.</p>
                    <p>Nossa equipe está preparada para auxiliar as empresas na elaboração e revisão dos cálculos dos tributos, possibilitando uma maior segurança e antecipação de eventuais exposições e riscos fiscais, os tributos indiretos são uma das ferramentas importantes para avaliar a competitividade das empresas no mercado nacional, considerar a estrutura mais eficiente pode ser fator decisivo para a geração de resultados.</p>
                    <p>Nosso trabalho tem por objetivo, ajudar a administração a entender as modificações e os aspectos complexos da legislação, a fim de apoiar o processo de gestão de riscos e, sobretudo, a identificação de oportunidades para melhor equacionar a carga tributária.</p>
                </div>
                <div className='impostosDiretosIndiretos-box'>
                    <img style={{width: "100%"}} src='./impostos-diretos-e-indiretos.png' alt=''/>
                    <p style={{fontWeight: "bold"}}>Oferecemos uma série de serviços de assessoria e consultoria tributária para assegurar uma gestão eficaz dos custos fiscais na esfera dos tributos indiretos.</p>
                </div>
            </div>
        </div>
    )
}