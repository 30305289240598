import {useState, useEffect, useRef} from 'react'
import './Diferenciais.css'     
import React from 'react';
import { motion } from 'framer-motion'
import Cards from './Cards'



function Diferenciais() {


    const carousel = useRef();
    const [width, setWidth] = useState(0);

    useEffect(() => {
        setWidth(carousel.current?.scrollWidth - carousel.current?.offsetWidth)
    }, [])



    return (
        <div className='diferenciais'>
            <h1 id='diferenciais'>Diferenciais</h1>
            <p>Na Letter Consulting , inspiramos confiança e empoderamos <br/>a mudança em tudo o que fazemos por nossos clientes, por <br/>nossas pessoas e pelas comunidades a que servimos.</p>
            <div className='cardsMotion'>

                <motion.div ref={carousel} className='carousel' whileTap={{ cursor: 'grabbing' }}>
                    <motion.div className='inner'
                        drag='x'
                        dragConstraints={{ right: 0, left: -width }}
                        initial={{ x: 100 }}
                        animate={{ x: 0 }}
                        transition={{ duration: 0.8 }}
                    >


                            <motion.div className='item'>
                                <Cards/>
                            </motion.div>


                    </motion.div>
                </motion.div>

            </div>
            <div className='botao-verde'>
                <a href='#topo'>solicitar análise gratuita</a>
            </div>
        </div>
    )
}

export default Diferenciais