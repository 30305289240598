import './style.css'

export default function Transacoes() {
    return (
        <div id='transacoes' className='transacoes'>
            <div className='transacoes-area'>
                <h1>Transação e NJP</h1>
                <div className='transacoes-box'>
                    <div className='transacoes-transacoes'>
                        <h3>Transações</h3>
                        <ul>
                            <li>O Estado tem o dever de incentivar as partes à autocomposição, sempre quando for oportuno e possível. Essa premissa, inclusive, está prevista no próprio Código de Processo Civil (art. 3º, §2º e 3º).</li>
                            <li>Dessa forma, a Procuradoria-Geral da Fazenda Nacional (PGFN) publicou três novas normas que regulamentam a transação de créditos inscritos em dívida ativa da União, em razão da conversão da Medida Provisória nº 899/2019 na Lei nº 13.988, de 14.04.2020, que estabelece as condições para transação extraordinária na cobrança da dívida ativa da União, em função dos efeitos da pandemia causada pelo novo corona vírus (CO-VID-19), que podem chegar:</li>
                            <li>
                                <ul>
                                    <li>Possibilidade de amortizar ou liquidar a dívida com precatórios federais próprios ou de terceiros.</li>
                                    <li>Descontos de até 50% sobre o valor total da dívida, que podem chegar a 70% em caso de pessoa física, empresário individual, microempresa ou empresa de pequeno porte em recuperação judicial.</li>
                                    <li>Parcelamento do débito em até 84 meses, que pode chegar a 145 meses na hipótese de pessoa física, empresário individual, microempresa ou empresa de pequeno porte em recuperação judicial.</li>
                                    <li>Carência de até 180 dias para início de pagamento, no caso de empresas em processo de recuperação judicial.</li>
                                    <li>Flexibilização das regras envolvendo prestação de garantias, penhora e alienação de bens.</li>
                                </ul>
                            </li>
                        </ul>
                    </div>
                    <div className='transacoes-njp'>
                        <h3>NJP</h3>
                        <ul>
                            <li>A redução ou ampliação dos prazos processuais O recebimento de intimações via correio eletrônico (e-mail).</li>
                            <li>A fixação de impenhorabilidade de terminado bem da parte.</li>
                            <li>A divisão das custas e despesas processuais.</li>
                            <li>Estabelecer a dispensa de nomeação de assistente técnico.</li>
                            <li>Às partes convencionarem a impossibilidade de execução provisória.</li>
                            <li>Fixarem, juntamente com o Magistrado, um calendário judicial para a prática de atos processuais às partes estabelecerem caução (de ordem genérica) que pode ser utilizada tanto para custeio na produção de eventual prova, quanto para arcar com as custas do processo e com os honorários da parte vencedora, dentre outros mecanismos que se amoldarão, repita-se, de acordo com a conveniência das partes.</li>
                        </ul>
                            <p>Em resumo, o Negócio Jurídico Processual está associado diretamente à ideia de flexibilização procedimental, e mais ainda, à ideia de tutela jurisdicional diferenciada, por meio da qual o procedimento deve se adaptar às exigências impostas pelo direito material no caso concreto para que a tutela seja real e efetiva.</p>
                        <img style={{width: "100%"}} src='./transacoes.png' alt='' />
                    </div>
                </div>
            </div>
        </div>
    )
}