import './style.css'

export default function RevisaoTributaria() {
    return (
        <div id='revisaoTributaria' className='revisaoTributaria'>
            <div className='revisaoTributaria-area'>
                <div className='revisaoTributaria-box'>
                    <h1>Revisão Tributária e <br/>Previdenciária</h1>
                    <p>Nosso diagnóstico objetiva a verificação de toda a base de tributação das obrigações principais e acessórias, fiscais e sociais, visando a restituição de valores pagos a maior e geração de caixa para companhia. Bem como, a identificar possíveis inconsistências e erros ensejadores de fiscalizações, autuações e ainda composição do débito fiscal, com metodologia exclusiva e ferramentas avançadas de BI Business Intelligence e CM – Content Management.</p>
                    <p style={{fontWeight: "bold"}}>A metodologia foi desenvolvida com base na curva de maturidade e complexidade de operações de uma empresa.</p>
                    <p>Sincronizados com as regras de negócio estabelecidas na legislação atual referente toda tributação nacional com as análises digitais sobre os arquivos de dados do cliente (validação, cruzamentos, conciliações, relatórios gerenciais, indicadores e saneamento de dados e informações) que de forma preventiva permite a antecipação aos erros nos dados que serão externados ao Fisco.</p>
                </div>
                <div className='revisaoTributaria-box'>
                    <img style={{width: "100%"}} src='./revisao-tributaria.png' alt='' />
                    <p>De forma direta e objetiva, geramos caixa para as companhias e de forma preventiva identificamos a qualidade dos dados, integridade, transparência, confiabilidade e segurança das informações que serão entregues ao Fisco, com isso, criamos uma base de informações para atendimento a futuras fiscalizações ou ainda encaminhando relatórios gerenciais aos Stakeholders.</p>
                </div>
            </div>
        </div>
    )
}