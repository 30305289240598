import './Confia.css'
import { motion } from 'framer-motion'
import { useEffect, useRef, useState } from 'react'

import band from './band.png'
import besni from './besni.png'
import engevix from './engevix.png'
import ericsson from './ericsson.png'
import fast from './fast.png'
import fiat from './fiat.png'
import iss from './iss.png'
import johnson from './johnson.png'
import massey from './massey.png'
import multilaser from './multilaser.png'
import tp from './tp.png'

const images = [
  { src: band, alt: 'Logo band' },
  { src: besni, alt: 'Logo besni' },
  { src: engevix, alt: 'Logo engevix' },
  { src: ericsson, alt: 'Logo ericsson' },
  { src: fast, alt: 'Logo fast' },
  { src: fiat, alt: 'Logo fiat' },
  { src: iss, alt: 'Logo iss' },
  { src: johnson, alt: 'Logo johnson' },
  { src: massey, alt: 'Logo massey' },
  { src: multilaser, alt: 'Logo multilaser' },
  { src: tp, alt: 'Logo tp' },
]

function Confia() {
  const carousel = useRef();
  const [width, setWidth] = useState(0);
  const [currentIndex, setCurrentIndex] = useState(0);

  useEffect(() => {
    setWidth(carousel.current?.scrollWidth - carousel.current?.offsetWidth)
  }, [currentIndex])

  const handleSlideChange = (event, info) => {
    const percent = info.offset.x / width;
    setCurrentIndex(Math.round((images.length - 1) * percent) % images.length);
    if (currentIndex <= -1) images.push(...images);
  }

  return (
    <div className='confia'>
      <h1>Empresas que confiam na Letter Consulting</h1>
      <div className='confia-marcas'>
        <motion.div ref={carousel} className='carousel-marcas' whileTap={{ cursor: 'grabbing' }}>
          <motion.div className='inner-marcas'
            drag='x'
            dragConstraints={{ right: 0, left: - width }}
            initial={{ x: 0 }}
            animate={{ x: 0 }}
            transition={{ duration: 0.8 }}
            style={{gap: "60px"}}
            onDragEnd={handleSlideChange}
          >
            {images.map((image, index) => (
              <motion.div className='item-marcas' key={index}>
                <img src={image.src} alt={image.alt} />
              </motion.div>
            ))}
          </motion.div>
        </motion.div>
      </div>
      <div className='hand'>
        <i className="fa-solid fa-hand"></i>
        <p style={{color: "rgb(121, 120, 120)"}}>(arraste para o lado)</p>
      </div>
        </div>
    )
}

export default Confia
